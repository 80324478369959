<template>
  <a
    :href="`${customerAppUrl}${avatar.to}`"
    class="my-5 flex w-full rounded-md pl-1 pr-1.5 hover:bg-grey-100"
  >
    <BaseAvatar
      v-if="initials || image"
      size="xs"
      :fallback-text="initials"
      :image="image"
      :fallback-style="avatarStyles"
    />
    <div class="ml-3 mt-[-2px] flex w-[104px] flex-col">
      <span
        class="block w-full truncate text-body font-medium text-grey-700"
        :title="avatar.label"
        >{{ avatar.label }}</span
      >
      <span
        class="block w-full truncate text-caption text-grey-500"
        :title="avatar.subLabel"
        >{{ avatar.subLabel }}</span
      >
    </div>
  </a>
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import { initials as initialsUtil } from "~/utils/initials";
import { useUserStore } from "~/store/UserStore";
const customerAppUrl = useRuntimeConfig().public.customerAppUrl;
const { user } = storeToRefs(useUserStore());

const initials = computed(() => {
  return initialsUtil(user.value?.name || "");
});

const image = computed(() => {
  if (!user.value?.media?.url) return null;

  return { url: user.value.media.url as string, alt: "User avatar image" };
});

const avatar = computed(() => {
  return {
    label: user.value?.name || "loading...",
    subLabel: user.value?.customer?.name || "loading...",
    to: "profile",
  };
});

const avatarStyles = computed(() => {
  function hue() {
    let charVal = 0;
    const seed = initials.value;
    for (let i = 0; i < seed.length; i += 1) {
      charVal += seed.charCodeAt(i) * 1000;
    }
    return charVal % 360;
  }

  return {
    background: `linear-gradient(135deg,hsla(${hue()}, 42%, 56%, 1) 0%, hsla(${hue()}, 39%, 51%, 1) 100%)`,
    color: "white",
  };
});
</script>
